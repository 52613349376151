import React from "react"
import Layout from "../components/layout"
import "./works.css"
import WorksFull from "../components/worksFull"
import Contact from "../components/contact"

export default () => (
  <Layout
    lang="en"
    pageUrl="/works"
    seoTitle="Works: discover our latest web and digital projects"
    seoDescription="Discover a selection of our latest works and get inspired. We love helping our clients bring their ideas to life."
  >
    <div id="works-full-background">
      <WorksFull lang="en" />
    </div>
    <div id="contact-worksfull-background">
      <Contact lang="en" />
    </div>
  </Layout>
)
